import React, { useEffect } from 'react';
import { navigate } from 'gatsby-link';
import { StaticImage } from 'gatsby-plugin-image';

import products from '@data/winter.json';

import Button from '@components/Button/Button';

import * as styles from './WinterGoods.module.css';

const WinterGoods = ({ detail, en }) => {
  const onClick = product => {
    navigate(product.url);
  };

  const handleJSONld = products => {
    if (typeof document !== 'undefined') {
      products.forEach(p => {
        let price = '25';
        if (p.id === '0002' || p.id === '0006') price = '17';
        if (p.id === '0003' || p.id === '0007') price = '12';
        if (
          p.id === '0004' ||
          p.id === '0008' ||
          p.id === '0009' ||
          p.id === '0010'
        ) {
          price = '10';
        }

        const images = p.images.map(i => {
          const image = require(`@static/img/${i}`).default;
          return `https://www.tizianobiellersport.com${image}`;
        });
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.textContent = JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name: !en
            ? `Noleggio ${p.name} a Champoluc - Valle d'Aosta`
            : `${p.name} Rental in Champoluc - Valle d'Aosta`,
          description: `${p.description_small.substr(0, 120)}...`,
          image: images,
          brand: 'Nordica, Elan, Fischer - Tiziano Bieller Sport',
          category: !en ? 'Noleggio Sci' : 'Ski Rental',
          model: p.name,
          review: [
            {
              '@type': 'Review',
              reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
              },
              author: {
                '@type': 'Person',
                name: 'Seb Quish',
              },
            },
            {
              '@type': 'Review',
              reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
              },
              author: {
                '@type': 'Person',
                name: 'Alessandro Conca',
              },
            },
            {
              '@type': 'Review',
              reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
              },
              author: {
                '@type': 'Person',
                name: 'Andrea Bottin',
              },
            },
          ],
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '4.6',
            bestRating: '5',
            ratingCount: '27',
            reviewCount: '27',
            worstRating: '1',
          },
          offers: {
            '@type': 'Offer',
            url: `https://www.tizianobiellersport.com${p.url}`,
            priceCurrency: 'EUR',
            price: price,
            availability: 'https://schema.org/InStock',
          },
        });

        document.head.appendChild(script);

        return true;
      });
    }
  };

  const renderWinterGoods = () => {
    return products.data
      .filter(p => (!en ? p.lang === 'it' : p.lang === 'en'))
      .map(product => {
        return (
          <a
            href={`#${product.id}`}
            key={product.id}
            className={styles.cardContainer}
            onClick={e => {
              e.preventDefault();
              onClick(product);
            }}
          >
            <div className={styles.card}>
              {(product.id === '0001' || product.id === '0005') && (
                <StaticImage
                  src={'../../static/img/ski/ski.webp'}
                  className={styles.image}
                  alt={product.name}
                  placeholder="blurred"
                  width={426}
                  height={355}
                />
              )}

              {(product.id === '0002' || product.id === '0006') && (
                <StaticImage
                  src={'../../static/img/ski/skikids.webp'}
                  className={styles.image}
                  alt={product.name}
                  placeholder="blurred"
                  width={426}
                  height={355}
                />
              )}

              {(product.id === '0003' || product.id === '0007') && (
                <StaticImage
                  src={'../../static/img/ski/snowshoes.webp'}
                  className={styles.image}
                  alt={product.name}
                  placeholder="blurred"
                  width={426}
                  height={355}
                />
              )}

              {(product.id === '0004' || product.id === '0008') && (
                <StaticImage
                  src={'../../static/img/ski/crampons.webp'}
                  className={styles.image}
                  alt={product.name}
                  placeholder="blurred"
                  width={426}
                  height={355}
                />
              )}

              {(product.id === '0009' || product.id === '0010') && (
                <StaticImage
                  src={'../../static/img/ski/bob.webp'}
                  className={styles.image}
                  alt={product.name}
                  placeholder="blurred"
                  width={426}
                  height={355}
                />
              )}

              <h3 className={styles.title}>{product.name}</h3>
              <p className={styles.desc}>{product.description_small}</p>
              <p className={styles.price}>
                {product.prices.full}
                <br />
                {product.prices.half || ''}
              </p>
              <Button
                onClick={() => {
                  onClick(product);
                }}
                full
              >
                {!en ? 'SCOPRI DI PIU' : 'DISCOVER MORE'}
              </Button>
            </div>
          </a>
        );
      });
  };

  useEffect(() => {
    handleJSONld(products.data);
  }, []);

  const title = !en
    ? 'Sci, Scarponi, Ciaspole, Ramponcini e Bob a Noleggio'
    : 'Skis, Boots, Snowshoes, Crampons & Bob Rental';

  const desc = !en
    ? "Il nostro Noleggio potra fornirti tutta l'attrezzatura necessaria per le tue giornate sulla neve nel Monterosa Ski"
    : 'Our rental will be able to provide you with all the equipment you need for your days on the snow in Monterosa Ski';

  return (
    <div className={styles.container}>
      {detail ? (
        <h1 className={styles.containerTitle}>{title}</h1>
      ) : (
        <h2 className={styles.containerTitle}>{title}</h2>
      )}
      {detail && <p className={styles.containerDesc}>{desc}</p>}

      {renderWinterGoods()}

      <div className={styles.other}>
        {!en && (
          <strong>
            Hai bisogno di qualche informazione aggiuntiva, sei interessato ad
            un noleggio stagionale, o vuoi prenotare la tua attrezzatura?
          </strong>
        )}
        {en && (
          <strong>
            You need some additional information, you are interested in a
            seasonal rental, or do you want to book your equipment?
          </strong>
        )}
        <br />
        {!en && (
          <span>
            Contattaci subito via email{' '}
            <a href="mailto:info@tizianobiellersport.com">
              info@tizianobiellersport.com
            </a>{' '}
            o telefonicamente <a href="tel:3333408102">333.3408102</a> -{' '}
            <a href="tel:0125308111">0125.308111</a>
          </span>
        )}

        {en && (
          <span>
            Contact us by email{' '}
            <a href="mailto:info@tizianobiellersport.com">
              info@tizianobiellersport.com
            </a>{' '}
            or by phone <a href="tel:3333408102">333.3408102</a> -{' '}
            <a href="tel:0125308111">0125.308111</a>
          </span>
        )}
      </div>
    </div>
  );
};

export default WinterGoods;
