import React from 'react';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import WinterGoods from '@components/WinterGoods/WinterGoods';

const WinterPage = ({ location }) => {
  return (
    <Layout location={location} map>
      <Seo
        title="Noleggio Sci, Ciaspole, Ramponcini e Bob a Champoluc - Tiziano Bieller Sport"
        lang="it"
      />
      <WinterGoods detail />
    </Layout>
  );
};

export default WinterPage;
