// extracted by mini-css-extract-plugin
export var card = "WinterGoods-module--card--70240";
export var cardContainer = "WinterGoods-module--cardContainer--45b42";
export var container = "WinterGoods-module--container--ec5d6";
export var containerDesc = "WinterGoods-module--containerDesc--d278a";
export var containerTitle = "WinterGoods-module--containerTitle--1276f";
export var desc = "WinterGoods-module--desc--d11bf";
export var image = "WinterGoods-module--image--37473";
export var other = "WinterGoods-module--other--56da1";
export var price = "WinterGoods-module--price--5e519";
export var separator = "WinterGoods-module--separator--11a4c";
export var title = "WinterGoods-module--title--26f14";